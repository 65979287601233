import { Button, Col, Row } from 'reactstrap';

export default function FormSubmitButton(props) {
  return (
    <Row className="mt-2">
      <Col sm={{ offset: 2, size: 10 }}>
        <Button type="submit" color="primary">Save</Button>
      </Col>
    </Row>
  )
}
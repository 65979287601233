import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../Api";

export default function FeatureFlags() {
  const [featureFlags, setFeatureFlags] = useState();

  const sortByLabel = (a, b) => {
    return a.label.localeCompare(b.label);
  };

  let params = useParams();

  useEffect(() => {
    api
      .get(`appconfig/segments/${params.segment}/featureflags`)
      .then((response) => {
        setFeatureFlags(response.data.sort(sortByLabel));
      });
  }, [params.segment]);

  if (!featureFlags) {
    return <b>Loading...</b>;
  } else {
    return (
      <form
        style={{
          border: "1px solid #dedede",
          margin: "20px auto",
          maxWidth: "100%",
          padding: "30px 30px",
          justifyContent: "space-around",
        }}
        id="featureflags"
      >
        <h3>Feature flags</h3>

        <ul>
          {featureFlags.map((item) => (
            <li key={item.label + ":" + item.name}>
              {`${item.label} - ${item.name} - ${item.enabled.toString()}`}

              {item.enabledFor && (
                <ul>
                  {item.enabledFor.map((enabledForItem) => (
                    <li key={item.label + ":" + enabledForItem}>
                      {enabledForItem}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </form>
    );
  }
}

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardTitle, CardText } from 'reactstrap';

import { api } from "../Api";

const MaintenanceComponent = () => {

  let params = useParams();
  const [maintenance, setmaintenance] = useState({});
  const [newMaintenance, setNewMaintenance] = useState({
    startTime: '',
    endTime: '',
    title: JSON.stringify({
      en: "",
      nl: ""
    }, null, 2),
    description: JSON.stringify(
      {
        en: "",
        nl: ""
      }, null, 2
    )
  });

  useEffect(() => {
    fetchmMintenance();
  }, []);

  const fetchmMintenance = async () => {
    try {
      const response = await api.get(`tenant/${params.tenantId}/tenantsconfigurationruntime/maintenance`);
      console.log(response.data)

      setmaintenance(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };



  const handleAddnewMaintenance = async (event) => {
    event.preventDefault();

    const maintenance = {
      ...newMaintenance,
      title: JSON.parse(newMaintenance.title),
      description: JSON.parse(newMaintenance.description)
    }

    api.post(`tenant/${params.tenantId}/tenantsconfigurationruntime/maintenance`, maintenance)
      .then(function (response) {
        console.log(response);
        fetchmMintenance();

      })
      .catch(function (error) {
        alert(error);
        console.log(error);
      })




  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewMaintenance({ ...newMaintenance, [name]: value });
  };








  const deleteMaintenance = () => {

    api.delete(`tenant/${params.tenantId}/tenantsconfigurationruntime/maintenance`)
      .then(function (response) {
        console.log(response);
        fetchmMintenance();

      })
      .catch(function (error) {
        alert(error);
        console.log(error);
      })




  };


  // Render notifications and provide a form to add a new one
  return (
    <div>

      <Card className="mt-3 mb-3">
        <CardBody>
          <CardTitle tag="h4">Maintenance Manager</CardTitle>





          {maintenance ? (
            <Card className="mb-2">
              <CardBody>
                <CardTitle tag="h5">
                  {/* Title or other information here */}
                </CardTitle>
                <CardText>
                  <b>Start:</b> {new Date(maintenance.startTime).toLocaleString()} <br />
                  <b>End:</b> {new Date(maintenance.endTime).toLocaleString()}
                  <br />
                  <b>bypassUrl:</b> <a href={`https://${maintenance.url}?maintanancekey=${maintenance.key}`} target="_blank">{maintenance.url}</a>
                  <br /><br />

                  <br />
                  <Button color="danger" onClick={deleteMaintenance}>
                    Delete
                  </Button>
                </CardText>
              </CardBody>
            </Card>
          ) : (




            <Form onSubmit={handleAddnewMaintenance}>

              <FormGroup>
                <Label for="starttime">Start Time</Label>
                <Input
                  type="datetime-local"
                  name="startTime"
                  id="starttime"
                  value={newMaintenance.starttime}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="endtime">End Time</Label>
                <Input
                  type="datetime-local"
                  name="endTime"
                  id="endtime"
                  value={newMaintenance.endtime}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  name="title"
                  type="textarea"
                  id="title"
                  value={newMaintenance.title}
                  onChange={handleChange}
                />
              </FormGroup>



              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={newMaintenance.description}
                  onChange={handleChange}
                />
              </FormGroup>


              {/* Add inputs for Severity and Content */}
              <Button type="submit">Add Maintenance</Button>
            </Form>
          )}
          <hr />



        </CardBody>
      </Card>


    </div>
  );
};

export default MaintenanceComponent;

import Ajv from "ajv";
import React, { useEffect, useState, useRef, useContext } from "react";
import { FaArrowLeft, FaFrog, FaShippingFast, FaSync } from "react-icons/fa";
import { SiAzuredevops } from "react-icons/si";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonToolbar,
  Spinner,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  AutoForm,
  SubmitField,
  AutoFields,
  ErrorsField,
} from "uniforms-bootstrap5";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { api } from "../Api";
import TenantBadges from "./TenantBadges";
import { AuthRoleContext } from "../AuthRoleContext";
import AppConfig from "./AppConfig";

export default function Tenant() {
  // tenant
  const [tenantError, setTenantError] = useState(null);
  const [tenantLoaded, setTenantLoaded] = useState(false);
  const [tenant, setTenant] = useState();

  // tenant config
  const [configError, setConfigError] = useState(null);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [tenantConfig, setTenantConfig] = useState();

  // schema
  const [schemaError, setSchemaError] = useState(null);
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const [schema, setSchema] = useState(null);

  // other
  const [refresh, setRefresh] = useState(0);
  const backgrondRefresh = useRef(false);
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState(false);

  const [activeTab, setActiveTab] = useState("static");

  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    setSaving(false);

    const abortController = new AbortController();

    if (backgrondRefresh.current) {
      // do background refresh only this request
      backgrondRefresh.current = false;
    } else {
      setTenantLoaded(false);
      setTenantError(null);
    }
    api
      .get(`tenant/${params.tenantId}`, {
        signal: abortController.signal,
      })
      .then(function (response) {
        setTenant(response.data);
        setTenantLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
        if (!abortController.signal.aborted) {
          setTenantError(error);
        }
      });

    setConfigLoaded(false);
    setConfigError(null);
    api
      .get(`tenant/${params.tenantId}/tenantsconfiguration`, {
        signal: abortController.signal,
      })
      .then((response) => {
        setTenantConfig(response.data);
        setConfigLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setConfigError(error);
        }
      });

    setSchemaLoaded(false);
    setSchemaError(null);
    api
      .get(`tenantsconfiguration/schema`, {
        signal: abortController.signal,
      })
      .then((response) => {
        setSchema(response.data);
        setSchemaLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setSchemaError(error);
        }
      });

    return () => {
      abortController.abort();
    };
  }, [params.tenantId, refresh]);

  useEffect(() => {
    if (tenant && tenant.activeReleaseLock) {
      const timeout = setTimeout(() => {
        backgrondRefresh.current = true;
        setRefresh((r) => r + 1);
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tenant]);

  const handleSubmit = (model) => {
    setSaving(true);

    return new Promise((resolve, reject) => {
      api
        .put(`tenant/${params.tenantId}/tenantsconfiguration`, model)
        .then((response) => {
          // refresh to show pipeline running state
          setRefresh(refresh + 1);
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
          setSaving(false);
        });
    });
  };

  const handleDelete = () => {
    setSaving(true);
    return new Promise((resolve, reject) => {
      api
        .put(`tenant/delete/pending/${params.tenantId}`)
        .then((response) => {
          navigate(`/subscription/${tenant.subscriptionId}/tenant`, {
            replace: true,
          });
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
          setSaving(false);
        });
    });
  };

  let bridge = null;
  if (schema) {
    const ajv = new Ajv({ allErrors: true, useDefaults: true });

    function createValidator(schema) {
      const validator = ajv.compile(schema);

      return (model) => {
        validator(model);
        return validator.errors?.length ? { details: validator.errors } : null;
      };
    }

    const schemaValidator = createValidator(schema);

    bridge = new JSONSchemaBridge(schema, schemaValidator);
  }

  const toggle = () => setModal(!modal);

  const roles = useContext(AuthRoleContext);

  console.log(roles);

  if (tenantError) {
    return <Alert color="danger">Error: {tenantError.message}</Alert>;
  } else if (!tenantLoaded) {
    return (
      <center>
        <Spinner type="grow">Loading</Spinner>
      </center>
    );
  } else {
    return (
      <div>
        <h1>Tenant {tenant.tenantDisplayname}</h1>
        <h4>{tenant.tenantName}</h4>

        <div className="mb-3">
          <TenantBadges tenant={tenant} />

          {roles.isAdmin && (
            <Button
              style={{ float: "right" }}
              size="sm"
              onClick={toggle}
              color="danger"
            >
              Delete
            </Button>
          )}
        </div>

        <ButtonToolbar className="mb-3">
          <Button
            className="me-1"
            tag={Link}
            to={`/subscription/${tenant.subscriptionId}/tenant`}
          >
            <FaArrowLeft /> subscription
          </Button>
          {/* TODO: Get pipeline url from backend */}
          <Button
            className="me-1"
            href={`https://dev.azure.com/embrace/Embrace%20Suite/_build?definitionId=${tenant.azureDevopsBuildDefinitionId}&amp;_a=summary`}
            target="_blank"
          >
            <SiAzuredevops /> view in Azure DevOps
          </Button>
          <Button className="me-1" tag={Link} to="runtime">
            Notifications & Maintenance
          </Button>
          <Button
            className="me-1"
            onClick={() => {
              setRefresh(refresh + 1);
            }}
          >
            <FaSync />
          </Button>
        </ButtonToolbar>
        <ButtonToolbar className="mb-3">
          <Button className="me-1" onClick={() => setActiveTab("static")}>
            Static config
          </Button>
          <Button className="me-1" onClick={() => setActiveTab("appconfig")}>
            Azure App config
          </Button>
        </ButtonToolbar>

        {tenant.activeReleaseLock && (
          <Alert color="warning">
            <h4 className="alert-heading">
              <FaShippingFast /> Active release
            </h4>
            <p>It is not possible to make changes while a release is busy.</p>
            <div>
              <Spinner type="grow" size="sm">
                Loading
              </Spinner>{" "}
              this page will reload continuously
            </div>
          </Alert>
        )}

        {schemaError && (
          <Alert color="danger">
            Error getting schema: {schemaError.message}
          </Alert>
        )}

        {!schemaError && activeTab === "static" && (
          <>
            {!tenant.activeReleaseLock && (
              <>
                {tenant.deployStatus === "initialized" ||
                tenant.deployStatus === "finished" ||
                tenant.deployStatus === "updatingFailed" ? (
                  configLoaded && schemaLoaded ? (
                    <AutoForm
                      schema={bridge}
                      onSubmit={handleSubmit}
                      model={tenantConfig}
                      disabled={saving}
                    >
                      <AutoFields />
                      <ErrorsField />
                      <ButtonGroup>
                        <SubmitField value="Save and start deploy" />
                      </ButtonGroup>
                    </AutoForm>
                  ) : configError ? (
                    <Alert color="danger">
                      Error getting config: {configError.message}
                    </Alert>
                  ) : (
                    <Alert color="light">
                      <Spinner type="grow" size="sm">
                        Loading
                      </Spinner>{" "}
                      loading tenant configuration, this can take a few minutes
                    </Alert>
                  )
                ) : (
                  <Alert color="warning">
                    <h4 className="alert-heading">
                      <FaFrog /> Not possible to edit in current deploy status
                    </h4>
                    {tenant.deployStatus === "pendingDelete" ? (
                      <p>This tenant is in a deleting state</p>
                    ) : (
                      <p>
                        Please wait till the deploy is in the initialized or
                        finished state.
                      </p>
                    )}
                  </Alert>
                )}
              </>
            )}
          </>
        )}

        {activeTab === "appconfig" && <AppConfig />}
        {console.log(activeTab)}

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            Deleting tenant {tenant.tenantDisplayname}
          </ModalHeader>
          <ModalBody>
            You are about to delete {tenant.tenantDisplayname}, this means{" "}
            <text style={{ color: "red" }}>
              all resources including data will be removed
            </text>{" "}
            for this tenant. <br />
            <br />
            When you press delete a mail is being send to your email, this
            contains a link to perform the actual delete
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDelete}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

import { Col, Row, Input, Label } from 'reactstrap';

export default function FormInput(props) {
  let {
    label,
    name,
    handler,
    ...attributes
  } = props;

  return (
    <Row className="mb-1">
      <Label for={`input-${props.name}`} sm={2}>{label}</Label>
      <Col sm={10}>
        <Input {...attributes} id={`input-${name}`} type="text" name={name} onChange={e => handler(e.target.value)} />
      </Col>
    </Row>
  )
}
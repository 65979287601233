import React, { useEffect, useState } from 'react';
import { FaSearch, FaShoePrints, FaSync, FaPenSquare } from 'react-icons/fa';
import { Link, useParams } from "react-router-dom";
import { Table, Alert, Button, ButtonToolbar, Input, InputGroup, InputGroupText, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import { api } from "../Api";
import TenantBadges from './TenantBadges';

export default function UpdateJob() {

  function renderTable(tenants) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>tenant</th>
          </tr>
        </thead>
        <tbody>
          {tenants.map(tenant =>
            <tr key={tenant.id}>
              <td>
                <Link to={`/tenant/${tenant.id}`}>{`${tenant.tenantName}`}</Link>
              </td>
              <td>
                <TenantBadges tenant={tenant} />
              </td>
            </tr>
          )}
          {tenants.length === 0 &&
            <tr key="noresult">
              <td><p className="text-muted text-center"><FaShoePrints /> no tenants in update job</p></td>
            </tr>
          }
        </tbody>
      </table>
    );
  }



  function renderAffectedTenants(tenants) {
    console.log(tenants)
    return (
      <div>
        <Table >
          <thead>
            <tr>
              <th>Affected tenants</th>

            </tr>
          </thead>
          <tbody>
            {tenants.map(tenant =>
              <tr key={tenant.tenantName}>
                <td>
                  {tenant.tenantName}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  function updateAffectedTenants() {

  }

  let params = useParams();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [tenants, setTenants] = useState([]);
  const [tenantsLoading, setTenantsLoading] = useState(true);
  const [rversion, setRversion] = useState("");
  const [environment, seteEnvironment] = useState("");
  const [subscription, setSubscription] = useState("");



  useEffect(() => {

    setTenantsLoading(true);

    api.get(`tenant/filter?rversion=${rversion}&environment=${environment}&subscription=${subscription}`, {
    })
      .then(function (response) {
        setTenantsLoading(false);
        setTenants(response.data);

      })
      .catch(function (error) {

      })

  }, [rversion, environment, subscription])


  useEffect(() => {
    setIsLoaded(false);
    setError(null);

    const abortController = new AbortController();
    api.get('getroles')
      .then(function (response) {
        console.log(response.data);
      });
    api.get(`deploymentjob/${params.jobId}`, {
      signal: abortController.signal
    })
      .then(function (response) {
        setSubscriptions(response.data);
        setIsLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setError(error);
        }
      })


    return () => {
      abortController.abort();
    };
  }, [refresh]);


  const CreateDeployment = () => {
    api.post("deploymentjob", tenants)
      .then(function (response) {
        console.log(response);
        cancelModal();
        setRefresh(refresh + 1);
      })
  }

  const showModal = () => {
    api.get('tenant/filter', {
    })
      .then(function (response) {
        setTenantsLoading(false);
        setTenants(response.data);

      })
      .catch(function (error) {

      })
    setModalIsOpen(true)
  };

  const cancelModal = () => {
    setModalIsOpen(false)
  };

  const save = (subscription) => {
    setModalIsOpen(false);
    api.put(`subscription/${subscription.id}`, subscription)
      .then((response) => {
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        alert(error.message)
      })
  };

  if (error) {
    return <Alert color="danger">Error: {error.message}</Alert>;
  } else {
    return (
      <div>

        <h1 className="mb-4">{subscriptions.createdBy}_{subscriptions.creationTime}</h1>

        <ButtonToolbar className="justify-content-between">
          <div>


          </div>

        </ButtonToolbar>

        {isLoaded ?
          renderTable(subscriptions.tenants)
          :
          <center><Spinner type="grow">Loading</Spinner></center>
        }
      </div>
    );
  }
}


import React, { useEffect, useState, useContext } from 'react';
import { FaSearch, FaSync, FaShoePrints, FaPenSquare } from 'react-icons/fa';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Alert, Button, ButtonToolbar, Input, InputGroup, InputGroupText, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { api } from "../Api";
import TenantBadges from './TenantBadges';
import { AuthRoleContext } from '../AuthRoleContext'

export default function Tenants() {
  function renderTable(tenants) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {tenants.map(tenant =>
            <tr key={tenant.id}>
              <td>
                <Link to={`/tenant/${tenant.id}`}>{tenant.tenantDisplayname}</Link>
                <TenantBadges tenant={tenant} />
              </td>
              <td>
                <Button onClick={() => showModal(tenant)}><FaPenSquare /></Button>
              </td>
            </tr>

          )}
          {tenants.length === 0 &&
            <tr key="noresult">
              <td><p className="text-muted text-center"><FaShoePrints /> no tenants</p></td>
            </tr>
          }
        </tbody>

      </table >

    );
  }

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTenantsLoaded, setIsTenantsLoaded] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [tenants, setTenants] = useState([]);
  const [subscription, setSubscription] = useState();
  const [refresh, setRefresh] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState();
  let params = useParams();
  let navigate = useNavigate();
  const roles = useContext(AuthRoleContext);


  const fetchSubscription = () => {
    setIsLoaded(false);
    setError(null);

    const abortController = new AbortController();
    api.get(`subscription/${params.subscriptionId}`, {
      signal: abortController.signal
    })
      .then(function (response) {
        setSubscription(response.data);
        setIsLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setError(error);
        }
      })

    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchSubscription();
  }, [params.subscriptionId]);

  useEffect(() => {
    setIsTenantsLoaded(false);
    setError(null);

    const abortController = new AbortController();
    api.get(`subscription/${params.subscriptionId}/tenant`, {
      signal: abortController.signal
    })
      .then(function (response) {
        setTenants(response.data);
        setIsTenantsLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setError(error);
        }
      })

    return () => {
      abortController.abort();
    };
  }, [params.subscriptionId, refresh]);


  const handleDelete = () => {

    return new Promise((resolve, reject) => {
      api.delete(`tenant/${params.tenantId}`)
        .then((response) => {
          navigate(`/subscription/`, { replace: true });
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
          alert(error.message)
        })
    });
  };

  const showModal = (tenant) => {
    setSelectedTenant(tenant);
    setModalIsOpen(true)
  };

  const cancelModal = () => {
    setModalIsOpen(false)
  };

  const save = (tenant) => {
    setModalIsOpen(false);
    api.put(`tenant/${tenant.id}`, tenant)
      .then((response) => {
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        alert(error.message)
      })
  };

  if (error) {
    return <Alert color="danger">Error: {error.message}</Alert>;
  } else if (!isLoaded) {
    return <center><Spinner type="grow">Loading</Spinner></center>;
  } else {
    return (
      <div>
        <EditTenantModal isOpen={modalIsOpen} cancel={cancelModal} save={save} tenant={selectedTenant} />
        <h1 className="mb-4">Tenants for {subscription?.customerName}</h1>

        <ButtonToolbar className="justify-content-between">
          <div>
            <Button className="me-1" tag={Link} to="new">New</Button>
            <Button className="me-1" onClick={() => { setRefresh(refresh + 1) }}><FaSync /></Button>
          </div>
          {isTenantsLoaded &&
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input type="text" name="search" placeholder="search.." value={searchString} onChange={(e) => setSearchString(e.target.value)} />
            </InputGroup>
          }
        </ButtonToolbar>

        {isTenantsLoaded ?


          tenants.length > 0 ?

            <>
              {renderTable(tenants.filter((item) => { return !searchString || item.tenantName.toLowerCase().includes(searchString.toLowerCase()) || item.tenantDisplayname.toLowerCase().includes(searchString.toLowerCase()) }))}
            </>

            :
            roles.isAdmin &&
            <div></div>
          :
          <center><Spinner type="grow">Loading</Spinner></center>
        }
      </div>
    );
  }

}

class EditTenantModal extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} className={this.props.className}>
          <ModalHeader>Change display name: </ModalHeader>
          <ModalBody>
            <input defaultValue={this.props.tenant?.tenantDisplayname} ref={this.myRef}></input>
          </ModalBody>
          <ModalFooter>
            <Button className="mx-1" color="info" onClick={() => this.props.save({ ...this.props.tenant, tenantDisplayname: this.myRef.current?.value })}>Save</Button>
            <Button color="secondary" onClick={this.props.cancel}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div >
    );
  }
}
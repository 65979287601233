import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Col, Form, Input, Label, Row, Spinner } from 'reactstrap';
import { api } from '../Api';
import { ConfigContext } from '../ConfigContext';
import FormInput from './FormInput';
import FormSubmitButton from './FormSubmitButton';

export default function NewTenant() {
  const navigate = useNavigate();
  let params = useParams();
  const configContext = useContext(ConfigContext);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    setSaving(true);

      api.post(`subscription/${params.subscriptionId}/tenant`, {
          tenantName: tenantName.toLowerCase(),
      tenantDisplayname: tenantDisplayname,
      environment: environment
    })
      .then(function (response) {
        console.log(response);
        navigate(`/tenant/${response.data.id}`);
      })
      .catch(function (error) {
        setSaving(false);
        alert(error);
        console.log(error);
      })
  }

  useEffect(() => {
    setError(null);
    setIsLoaded(false);

    const abortController = new AbortController();
    api.get(`subscription/${params.subscriptionId}`, {
      signal: abortController.signal
    })
      .then(function (response) {
        setSubscription(response.data);
        setIsLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setError(error);
        }
      })

    return () => {
      abortController.abort();
    };
  }, [params.subscriptionId]);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [subscription, setSubscription] = useState();

  const [tenantName, setTenantName] = useState('');
  const [tenantDisplayname, setTenantDisplayname] = useState('');
  const [environment, setEnvironment] = useState(configContext.environments[0]);

  if (error) {
    return <Alert color="danger">Error: {error.message}</Alert>;
  } else if (!isLoaded) {
    return <center><Spinner type="grow">Loading</Spinner></center>;
  } else {
    return (
      <div>
        <h1>New tenant for {subscription.customerName}</h1>

        <Form onSubmit={handleSubmit}>
          <fieldset disabled={saving}>
            <FormInput name="tenantName" label="Tenant name" value={tenantName} handler={setTenantName} maxlength="17" pattern="[A-Za-z0-9-]{3,17}" />
            <FormInput name="tenantDisplayname" label="Tenant displayname" value={tenantDisplayname} handler={setTenantDisplayname} />
            <Row className="mb-1">
              <Label for="input-environment" sm={2}>Environment</Label>
              <Col sm={10}>
                <Input id="input-environment" type="select" name="environment" value={environment} onChange={e => setEnvironment(e.target.value)}>
                  {configContext.environments.map(env =>
                    <option key={env}>{env}</option>
                  )}
                </Input>
              </Col>
            </Row>
            <FormSubmitButton />
          </fieldset>
        </Form>
      </div>
    );
  }
}

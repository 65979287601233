import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { MdLogout } from "react-icons/md";
import { api } from "../Api";

export default function NavMenu() {
  function toggleNavbar() {
    setCollapsed(!collapsed);
  }

  const { instance } = useMsal();
  const [collapsed, setCollapsed] = useState(true);
  const [name, setName] = useState(null);
  const [segments, setSegments] = useState([]);

  const activeAccount = instance.getActiveAccount();
  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  useEffect(() => {
    api.get("appconfig/segments").then((response) => {
      setSegments(response.data);
    });
  }, []);

  const logout = () => {
    instance.logoutRedirect({
      onRedirectNavigate: (url) => {
        // Don't logout on server
        setTimeout(() => {
          window.location.href = "/";
        }, 300);
        return false;
      },
    });
  };
  return (
    <header>
      <Navbar color="light" expand="md" light>
        <NavbarBrand tag={Link} to="/">
          tenants
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={!collapsed} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/subscription">
                Subscriptions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/updatejobs">
                Update Jobs
              </NavLink>
            </NavItem>
            {segments.map((segment) => (
              <NavItem key={segment}>
                <NavLink tag={Link} to={`/featureflags/${segment}`}>
                  Feature flags {segment}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <NavbarText>
            {name}
            &nbsp;
            <button
              className="btn btn-link py-0"
              title="Logout of tenants app (doesn't logout on Azure)"
              onClick={() => logout()}
            >
              <MdLogout />
            </button>
          </NavbarText>
        </Collapse>
      </Navbar>
    </header>
  );
}

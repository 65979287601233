import React, { createContext, useState, useEffect } from "react";
import { api } from "./Api";

const AuthRoleContext = createContext();

const AuthRoleContextProvider = ({ children }) => {

  const [roles, setRoles] = useState(null);

  useEffect(() => {
    const fetchRoles = () => {

      api.get('getroles')
        .then(function (response) {
          setRoles({
            isAdmin: response.data.includes("tenant.admin"),
            isSecertReader: response.data.includes("tenant.readsecret"),
            isWriter: response.data.includes("tenant.write"),
            isLister: response.data.includes("tenant.list")
          })

        });
    };

    fetchRoles();
  }, []);

  return (

    <AuthRoleContext.Provider value={roles}>
      {children}
    </AuthRoleContext.Provider>
  );
};


export { AuthRoleContext, AuthRoleContextProvider };


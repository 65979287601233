import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

import { api } from "../Api";

const NotificationsComponent = () => {
  let params = useParams();
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState({
    startTime: "",
    endTime: "",
    severity: 1,
    content: JSON.stringify(
      {
        en: {
          main: "main content",
          title: "title content",
        },
        nl: {
          main: "main nl content",
          title: "main nl title",
        },
      },
      null,
      2
    ),
  });

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await api.get(
        `tenant/${params.tenantId}/tenantsconfigurationruntime/notifications`
      );
      console.log(response.data);
      if (response.data ?? response.data.count > 0) {
        setNotifications(response.data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const updateNotifications = (updatedNotifications) => {
    try {
      api
        .post(
          `tenant/${params.tenantId}/tenantsconfigurationruntime/notifications`,
          updatedNotifications
        )
        .then(function (response) {
          console.log(response);
          setNotifications(response.data);
        })
        .catch(function (error) {
          alert(error);
          console.log(error);
        });
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const handleAddNotification = async (event) => {
    event.preventDefault();

    try {
      const updatedNotification = {
        ...newNotification,
        content: JSON.parse(newNotification.content),
      };

      console.log(newNotification);
      updatedNotification.startTime = new Date(updatedNotification.startTime);

      console.log(newNotification);

      notifications.push(updatedNotification);

      updateNotifications(notifications);
      setNewNotification({
        startTime: "",
        endTime: "",
        severity: 1,
        content: JSON.stringify(
          {
            en: {
              main: "main content",
              title: "title content",
            },
            nl: {
              main: "main nl content",
              title: "main nl title",
            },
          },
          null,
          2
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewNotification({ ...newNotification, [name]: value });
  };

  const handlePriorityChange = (event) => {
    setNewNotification({ ...newNotification, Severity: event.target.value });
  };

  const priorityEnum = {
    1: "Info",
    3: "normal",
    5: "High",
    // Add other priority levels as needed
  };

  const deleteNotification = async (index) => {
    const updatedNotifications = notifications.filter((_, i) => i !== index);
    try {
      await updateNotifications(updatedNotifications);
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  // Render notifications and provide a form to add a new one
  return (
    <div>
      <Card className="mt-3 mb-3">
        <CardBody>
          <CardTitle tag="h4">Notifications Manager</CardTitle>

          {notifications && notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <Card key={index} className="mb-2">
                <CardBody>
                  <CardTitle tag="h5">
                    {/* Title or other information here */}
                  </CardTitle>
                  <CardText>
                    <b>Start:</b>{" "}
                    {new Date(notification.starttime).toLocaleString()} <br />
                    <b>End:</b>{" "}
                    {new Date(notification.endtime).toLocaleString()}
                    <br />
                    <b>Severity:</b> {notification.severity}
                    <br />
                    <br />
                    <pre>{JSON.stringify(notification.content, null, 2)}</pre>
                    <br />
                    <Button
                      color="danger"
                      onClick={() => deleteNotification(index)}
                    >
                      Delete
                    </Button>
                  </CardText>
                </CardBody>
              </Card>
            ))
          ) : (
            <p>No notifications available.</p> // Message displayed when there are no notifications
          )}
          <hr />

          <Form onSubmit={handleAddNotification}>
            <FormGroup>
              <Label for="prioritySelect">Priority</Label>
              <Input
                type="select"
                name="Severity"
                id="prioritySelect"
                onChange={handlePriorityChange}
              >
                {Object.entries(priorityEnum).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="startTime">Start Time</Label>
              <Input
                type="datetime-local"
                name="startTime"
                id="startTime"
                value={newNotification.startTime}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endTime">End Time</Label>
              <Input
                type="datetime-local"
                name="endTime"
                id="endTime"
                value={newNotification.endTime}
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="contentInput">Content (JSON format)</Label>
              <Input
                type="textarea"
                name="content"
                id="contentInput"
                defaultValue={newNotification.content}
                onChange={handleChange}
              />
            </FormGroup>
            {/* Add inputs for Severity and Content */}
            <Button type="submit">Add Notification</Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default NotificationsComponent;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'reactstrap';
import { api } from '../Api';
import FormInput from './FormInput';
import FormSubmitButton from './FormSubmitButton';

export default function NewSubscription() {

  const [customerName, setCustomerName] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (evt) => {
    evt.preventDefault();

    setSaving(true);
    api.post('subscription', { customerName: customerName })
      .then(function (response) {
        console.log(response);
        navigate(`/subscription/${response.data.id}/tenant`);
      })
      .catch(function (error) {
        setSaving(false);
        alert(error);
        console.log(error);
      })
  }

  const [saving, setSaving] = useState(false);
  return (
    <div>
      <h1>New subscription</h1>
      <Form onSubmit={handleSubmit}>
        <fieldset disabled={saving}>
          <FormInput name="customerName" label="Customer name" value={customerName} handler={setCustomerName} />
          <FormSubmitButton />
        </fieldset>
      </Form>
    </div >
  );


}

import {
  EventType,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import axios from "axios";
import React, { Component } from "react";
import { FaSkullCrossbones } from "react-icons/fa";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Alert, Container, Spinner } from "reactstrap";
import { Layout } from "./components/Layout";
import NewSubscription from "./components/NewSubscription";
import NewTenant from "./components/NewTenant";
import Subscriptions from "./components/Subscriptions";
import Tenant from "./components/Tenant";
import Tenants from "./components/Tenants";
import UpdateJobs from "./components/UpdateJobs";
import UpdateJob from "./components/UpdateJob";
import { ConfigContext } from "./ConfigContext";
import { AuthRoleContextProvider } from "./AuthRoleContext";
import DeleteTenant from "./components/DeleteTenant";
import TenantRuntimeConfiguration from "./components/TenantRuntimeConfiguration";
import FeatureFlags from "./components/FeatureFlags";

export let msalInstance = null;

export default class App extends Component {
  static displayName = App.name;
  state = {
    msalConfig: null,
    msalError: null,
    config: null,
  };

  componentDidMount() {
    axios
      .get("/api/clientappconfig")
      .then((res) => {
        this.setState({
          msalConfig: {
            auth: {
              clientId: res.data.authClientId,
              authority: res.data.authAuthority,
              redirectUri: "/",
            },
            cache: {
              cacheLocation: "sessionStorage", // This configures where your cache will be stored
              storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
          },
          authRequest: {
            scopes: [res.data.authScope],
          },
          config: {
            environments: res.data.environments.split(";"),
          },
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ msalError: error });
      });
  }

  render() {
    if (this.state.msalError !== null) {
      return (
        <Container className="mt-3">
          <Alert color="danger">
            <h4>
              <FaSkullCrossbones /> Could not load config from backend!
            </h4>
            Please wait a moment and refresh the page.
            <br />
            <br />
            Error: {this.state.msalError.message}
          </Alert>
        </Container>
      );
    } else if (this.state.msalConfig === null) {
      return (
        <center>
          <Spinner type="grow">Loading settings</Spinner>
        </center>
      );
    } else {
      msalInstance = new PublicClientApplication(this.state.msalConfig);

      console.log(msalInstance.getAllAccounts());

      if (
        !msalInstance.getActiveAccount() &&
        msalInstance.getAllAccounts().length > 0
      ) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }
      msalInstance.addEventCallback((event) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
        }
      });

      const authRequest = {
        ...this.state.authRequest,
      };

      return (
        <ConfigContext.Provider value={this.state.config}>
          <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={authRequest}
            >
              <AuthRoleContextProvider>
                <Layout>
                  <AuthenticatedTemplate>
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={<Navigate to="subscription" />}
                      />
                      <Route path="subscription" element={<Outlet />}>
                        <Route index element={<Subscriptions />} />
                        <Route path="new" element={<NewSubscription />} />
                        <Route
                          path=":subscriptionId/tenant"
                          element={<Outlet />}
                        >
                          <Route index element={<Tenants />} />
                          <Route path="new" element={<NewTenant />} />
                        </Route>
                      </Route>
                      <Route path="/updatejobs" element={<UpdateJobs />} />
                      <Route
                        path="/updatejobs/:jobId"
                        element={<UpdateJob />}
                      />
                      <Route path="/tenant/:tenantId" element={<Tenant />} />
                      <Route
                        path="/tenant/:tenantId/runtime"
                        element={<TenantRuntimeConfiguration />}
                      />
                      <Route
                        path="/tenant/delete/:tenantId"
                        element={<DeleteTenant />}
                      />
                      <Route
                        path="/featureflags/:segment"
                        element={<FeatureFlags />}
                      />
                    </Routes>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <p>You are not signed in! Please sign in.</p>
                  </UnauthenticatedTemplate>
                </Layout>
              </AuthRoleContextProvider>
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </ConfigContext.Provider>
      );
    }
  }
}

import Ajv from 'ajv';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { FaArrowLeft, FaFrog, FaShippingFast, FaSync } from 'react-icons/fa';
import { SiAzuredevops } from 'react-icons/si';
import { Link, useParams, useNavigate, } from "react-router-dom";
import { Alert, Button, ButtonToolbar, Spinner, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AutoForm, SubmitField, AutoFields, ErrorsField } from 'uniforms-bootstrap5';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { api } from "../Api";
import TenantBadges from './TenantBadges';
import { AuthRoleContext } from '../AuthRoleContext'
import NotificationsComponent from './Notifications';
import MaintenanceComponent from './Maintenance';

export default function TenantRuntimeConfiguration() {
  // tenant
  const [tenantError, setTenantError] = useState(null);
  const [tenantLoaded, setTenantLoaded] = useState(false);
  const [tenant, setTenant] = useState();

  // tenant config
  const [configError, setConfigError] = useState(null);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [tenantConfig, setTenantConfig] = useState();

  // schema
  const [schemaError, setSchemaError] = useState(null);
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const [schema, setSchema] = useState(null);

  // other
  const [refresh, setRefresh] = useState(0);
  const backgrondRefresh = useRef(false);
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState(false);
  let params = useParams();
  let navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date().toUTCString());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().toUTCString());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);


  return (
    <div>
      <b>UTC TIME: </b> {currentTime} <br />
      <NotificationsComponent></NotificationsComponent>
      <MaintenanceComponent></MaintenanceComponent>
      <Button onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </div>
  )

}



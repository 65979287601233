import Ajv from 'ajv';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { FaArrowLeft, FaFrog, FaShippingFast, FaSync } from 'react-icons/fa';
import { SiAzuredevops } from 'react-icons/si';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Alert, Button, ButtonToolbar, Spinner, ButtonGroup } from 'reactstrap';
import { AutoForm, SubmitField, AutoFields, ErrorsField } from 'uniforms-bootstrap5';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { api } from "../Api";
import TenantBadges from './TenantBadges';
import { AuthRoleContext } from '../AuthRoleContext'

export default function DeleteTenant() {
  // tenant
  const [tenantError, setTenantError] = useState(null);
  const [tenantLoaded, setTenantLoaded] = useState(false);
  const [tenant, setTenant] = useState();

  // tenant config
  const [configError, setConfigError] = useState(null);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [tenantConfig, setTenantConfig] = useState();

  // schema
  const [schemaError, setSchemaError] = useState(null);
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const [schema, setSchema] = useState(null);

  // other
  const [refresh, setRefresh] = useState(0);
  const backgrondRefresh = useRef(false);
  const [saving, setSaving] = useState(false);
  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    setSaving(false);

    const abortController = new AbortController();

    if (backgrondRefresh.current) {
      // do background refresh only this request
      backgrondRefresh.current = false;
    } else {
      setTenantLoaded(false);
      setTenantError(null);
    }
    api.get(`tenant/${params.tenantId}`, {
      signal: abortController.signal
    })
      .then(function (response) {
        setTenant(response.data);
        setTenantLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
        if (!abortController.signal.aborted) {
          setTenantError(error);
        }
      })


    setConfigLoaded(false);
    setConfigError(null);
    api.get(`tenant/${params.tenantId}/tenantsconfiguration`, {
      signal: abortController.signal
    })
      .then((response) => {
        setTenantConfig(response.data);
        setConfigLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setConfigError(error);
        }
      })

    setSchemaLoaded(false);
    setSchemaError(null);
    api.get(`tenantsconfiguration/schema`, {
      signal: abortController.signal
    })
      .then((response) => {
        setSchema(response.data);
        setSchemaLoaded(true);
      })
      .catch(function (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          setSchemaError(error);
        }
      })



    return () => {
      abortController.abort();
    };
  }, [params.tenantId, refresh]);

  useEffect(() => {
    if (tenant && tenant.activeReleaseLock) {
      const timeout = setTimeout(() => {
        backgrondRefresh.current = true;
        setRefresh(r => r + 1)
      }, 5000)
      return (() => {
        clearTimeout(timeout);
      })
    }
  }, [tenant]);

  const handleSubmit = (model) => {

    setSaving(true);

    return new Promise((resolve, reject) => {
      api.put(`tenant/${params.tenantId}/tenantsconfiguration`, model)
        .then((response) => {
          // refresh to show pipeline running state
          setRefresh(refresh + 1)
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
          setSaving(false);
        })
    });
  };

  const handleDelete = () => {
    setSaving(true);
    return new Promise((resolve, reject) => {
      api.delete(`tenant/${params.tenantId}`)
        .then((response) => {

          navigate(`/subscription/${tenant.subscriptionId}/tenant`, { replace: true });
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
          setSaving(false);
        })
    });
  }

  let bridge = null;
  if (schema) {
    const ajv = new Ajv({ allErrors: true, useDefaults: true });

    function createValidator(schema) {
      const validator = ajv.compile(schema);

      return (model) => {
        validator(model);
        return validator.errors?.length ? { details: validator.errors } : null;
      };
    }

    const schemaValidator = createValidator(schema);

    bridge = new JSONSchemaBridge(schema, schemaValidator);
  }


  const roles = useContext(AuthRoleContext);

  console.log(roles);

  if (tenantError) {
    return <Alert color="danger">Error: {tenantError.message}</Alert>;
  } else if (!tenantLoaded) {
    return <center><Spinner type="grow">Loading</Spinner></center>;
  } else {
    return (
      <div>
        <h1>
          Tenant {tenant.tenantDisplayname}
        </h1>
        <h4>{tenant.tenantName}</h4>

        <div className="mb-3">
          <TenantBadges tenant={tenant} />
        </div>


        {tenant.deployStatus == "pendingDelete" &&
          <Alert color="danger">
            <h4 className="alert-heading">Your are destroying {tenant.tenantName}</h4>
            <Button size='lg' color="danger" onClick={handleDelete}>Delete</Button>

          </Alert>
        }



      </div>
    );
  }

}



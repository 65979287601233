import { Badge, Spinner } from "reactstrap";
import { api } from "../Api";
import React, { useState } from "react";

export default function SupportLogin(props) {
  const [suportUrlLoading, setSuportUrlLoading] = useState(false);
  const env = props.tenant.environment;
  const tenant = props.tenant.tenantName;
  const tenantId = props.tenant.id;

  const handleLogin = () => {
    let supportUrl = "";

    setSuportUrlLoading(true);
    api
      .get(`tenant/${tenantId}/tenantsconfiguration/supporturl`)
      .then((response) => {
        let redirect = response.data;
        if (env == "production") {
          supportUrl = `https://auth.embracecloud.nl/auth/realms/${tenant}/protocol/openid-connect/auth?client_id=embracecloud&redirect_uri=${redirect}&response_type=code&scope=openid+suite-user+identity-provider-user&kc_idp_hint=support`;
        } else if (env == "beta") {
          supportUrl = `https://auth.${env}.embracecloud.nl/auth/realms/${tenant}/protocol/openid-connect/auth?client_id=embracecloud&redirect_uri=${redirect}&response_type=code&scope=openid+suite-user+identity-provider-user&kc_idp_hint=support`;
        } else {
          supportUrl = `https://auth.${env}.embracecloud.io/auth/realms/${tenant}/protocol/openid-connect/auth?client_id=embracecloud&redirect_uri=${redirect}&response_type=code&scope=openid+suite-user+identity-provider-user&kc_idp_hint=support`;
        }
        window.open(supportUrl, "_blank", "noopener noreferrer");
        setSuportUrlLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert("failed to build support session");

        setSuportUrlLoading(false);
      });
  };
  return (
    <>
      <Badge onClick={handleLogin} color="info" className="mx-1">
        {suportUrlLoading ? (
          <div>
            <Spinner size="sm">Loading</Spinner> Building support session
          </div>
        ) : (
          <div>Support login</div>
        )}
      </Badge>
    </>
  );
}

import axios from 'axios';
import { msalInstance } from './App'

export const api = axios.create({ baseURL: '/api/' });
api.interceptors.request.use(
  async request => {
    if (!msalInstance.getActiveAccount()) {
      throw new Error("No active msal account");
    }

    const response = await msalInstance.acquireTokenSilent({
      account: msalInstance.getActiveAccount()
    });

    const bearer = `Bearer ${response.accessToken}`;

    request.headers["Authorization"] = bearer;
    return request;
  }
);

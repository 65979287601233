import { Badge } from 'reactstrap';
import { FaFortAwesome, FaBirthdayCake, FaExclamation, FaLock, FaPlaneDeparture, FaShippingFast, FaGhost, FaIndustry } from 'react-icons/fa';

import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import SupportLogin from './SupportLogin';

export default function TenantBadges(props) {


  const [suportUrlLoading, setSuportUrlLoading] = useState(false);


  let params = useParams();

  function renderDeployStatus(deployStatus) {
    switch (deployStatus) {
      case "notCreated":
        return (<Badge className="mx-1">not created</Badge>);
      case "initializing":
        return (<Badge className="mx-1" color="warning"><FaPlaneDeparture /> initializing</Badge>);
      case "initializingFailed":
        return (<Badge className="mx-1" color="danger"><FaExclamation /> initialize failed</Badge>);
      case "initialized":
        return (<Badge className="mx-1" color="info"><FaBirthdayCake /> initialized</Badge>);
      case "updating":
        return (<Badge className="mx-1" color="warning"><FaShippingFast /> updating</Badge>);
      case "deleting":
        return (<Badge className="mx-1" color="warning"><FaShippingFast /> deleting</Badge>);
      case "updatingFailed":
        return (<Badge className="mx-1" color="danger"><FaExclamation /> updating failed</Badge>);
      case "pendingDelete":
        return (<Badge className="mx-1" color="danger"><FaExclamation /> pending delete</Badge>);
      case "finished":
        return (<Badge className="mx-1" color="success"><FaFortAwesome /> finished</Badge>);
      default:
        return (<Badge className="mx-1" color="danger"><FaGhost /> unknown state</Badge>);
    }
  }
  return (
    <>
      <Badge className="mx-1"><FaIndustry /> {props.tenant.environment}</Badge>




      {props.tenant.activeReleaseLock &&

        <Badge className="mx-1"><FaLock /> release lock</Badge>


      }
      {renderDeployStatus(props.tenant.deployStatus)}


      <SupportLogin tenant={props.tenant}></SupportLogin>

    </>
  )
}